<template>
  <DefaultModal
    ref="nodetoken_access_modal"
    @submit="isCurrent ? removeNodeTokenModal(selectedNodeToken) : addNodeTokenModal(node.node_id)"
    :submit-button-text="isCurrent ? $t('remove') : $t('nodetoken_new_key')"
    :deleteModal="isCurrent"
    :hideSaveButton="! isCurrent && ! isEmpty"
    :loading="loading"
    :save-button-disabled="loading"
  >
    <template v-slot:title>
      <span class="title-text left-align">
        <span class="headline">{{ $t('nodetoken_action_title') }}</span>
        <span class="font-italic normal"> - {{ node.name }}</span>
      </span>
    </template>

    <template v-slot:content>
      <v-list v-if="nodeNodeToken[0]" dense>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="font-italic">{{ nodeNodeToken[0].recursive ? $t('recursive') : $t('not_recursive') }}</v-list-item-title>

            <v-text-field
              :value="nodeNodeToken[0].node_token_key"
              type="text"
              outlined
              readonly
              dense
            >
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="copyKeyToClipboard(nodeNodeToken[0].node_token_key)"
                      class="mt-n3"
                      icon
                      large
                    ><v-icon>mdi-clipboard-multiple</v-icon></v-btn>
                  </template>

                  <span>{{ $t('node_token_clipboard_tooltip') }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-avatar class="avatar">
            <v-img
              v-if="getUserAvatarUrl(nodeNodeToken[0].users_id)"
              :src="getUserAvatarUrl(nodeNodeToken[0].users_id)"
              :alt="`${nodeNodeToken[0].users_id} avatar`"
            ></v-img>
            <span v-else class="secondary--text headline text-uppercase font-weight-regular">{{ getUserName(nodeNodeToken[0].users_id)[0] }}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <span class="font-weight-regular text-uppercase">{{ $t('created_by') }}</span>
            </v-list-item-title>

            <v-list-item-subtitle>
              <span class="text--primary">{{ getUserName(nodeNodeToken[0].users_id) }}</span>
              <span class=""> &mdash; {{ formatDate(nodeNodeToken[0].created_at) }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <span v-if="isEmpty" class="title-text left-align">
        <span class="normal">{{ $t('there_is_no_node_token') }}</span>
      </span>

      <DefaultModal
        :title="$t('add_nodetoken')"
        @submit="addNodeTokenCommit"
        ref="add_nodetoken_confirm_modal"
        submit-button-text="nodetoken_new_key"
        addModal
      >
        <template v-slot:content>
          <v-checkbox
            v-model="isRecursive"
            :label="$t('recursive_access')"
          ></v-checkbox>
        </template>
      </DefaultModal>

      <DefaultModal
        :title="$t('remove_nodetoken')"
        @submit="removeNodeToken"
        ref="remove_nodetoken_confirm_modal"
        submit-button-text="confirm"
        deleteModal
      >
        <template v-slot:content>
          <span>{{ $t('nodetoken_removal_confirmation') }}</span>
          <span class="font-weight-bold"> {{ node.name }}</span>?
        </template>
      </DefaultModal>
    </template>
  </DefaultModal>
</template>

<style scoped>
  .left-align {
    margin: 0 16px;
  }

  .title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .avatar {
    background-color: var(--v-primary-base) !important;
  }
</style>

<script>
import { mapActions } from 'vuex'

import DefaultModal from '@/components/DefaultModal'
import router from '@/router'
import AppActions from '@/store/app/actions-types'
import UserActions from '@/store/core/user/actions-types'
import NodeTokenActions from '@/store/node/nodetoken/actions-types'
import HumanReadable from "@/utils/human_readable"
import { forEach } from 'lodash'

export default {
  name: 'NodeTokenAccessModal',

  components: {
    DefaultModal,
  },

  data: () => ({
    loading: false,
    selectedNodeToken: {},
    nodeNodeToken: [],
    parentsNodeToken: [],
    title: "",
    isRecursive: true,
    isCurrent: false,
    isEmpty: false,
    node: {},
    users: [],
  }),

  computed: {
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_INFO_MESSAGE,
    ]),

    ...mapActions('core/user', [
      UserActions.GET_ALL_USERS,
    ]),

    ...mapActions('node/nodetoken', [
      NodeTokenActions.ADD_NODETOKEN,
      NodeTokenActions.DELETE_NODETOKEN,
      NodeTokenActions.GET_NODETOKEN_BY_NODE_AND_PARENTS,
    ]),

    fetchData() {
      this.getNodeTokenList()
    },

    getNodeTokenList() {
      this.loading = true
      this.nodeNodeToken = [...[]]
      const usersOptions = {
        'filter[tenant_id]': this.node.tenant_id,
        'filter[include_foreign]': true,
        'filter[active]': true,
        'limit': 9999,
      }

      const usersPromise = this[UserActions.GET_ALL_USERS](usersOptions)
      usersPromise.then((result) => {
        this.users = [...result.items]
      })

      const nodeTokenPromise = this[NodeTokenActions.GET_NODETOKEN_BY_NODE_AND_PARENTS](this.node.node_id)
        .then((result) => {
          this.nodeNodeToken = [...result]
          this.isCurrent = false
          this.isEmpty = result.length == 0
          if (!this.isEmpty) {
            this.selectedNodeToken = {...result[0]}
          }
          forEach(result, (item) => {
            if (item.node_id == this.node.node_id) {
              this.isCurrent = true
            }
          })
        })
        .finally(() => {
          this.loading = false
        })

      Promise.all([usersPromise, nodeTokenPromise])
        .finally(() => this.loading = false)
    },

    addNodeTokenModal(nodetoken) {
      this.$refs.nodetoken_access_modal.setIsSubmitting(false)

      this.selectedNodeToken = {...nodetoken}
      this.$refs.add_nodetoken_confirm_modal.openModal()
    },

    addNodeTokenCommit() {
      const data = {
        node_id: this.node.node_id,
        recursive: this.isRecursive,
        parameters: "{}",
      }

      this[NodeTokenActions.ADD_NODETOKEN](data)
        .then(() => {
          this.$refs.add_nodetoken_confirm_modal.closeModal()
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('nodetoken_added_successfully'))
          this.getNodeTokenList()
        })
    },

    removeNodeTokenModal(nodetoken) {
      this.$refs.nodetoken_access_modal.setIsSubmitting(false)

      this.selectedNodeToken = {...nodetoken}

      this.$refs.remove_nodetoken_confirm_modal.openModal()
    },

    removeNodeToken() {
      this[NodeTokenActions.DELETE_NODETOKEN](this.selectedNodeToken.node_token_id)
        .then(() => {
          const index = this.nodeNodeToken.map((ng) => {return ng.node_token_id}).indexOf(this.selectedNodeToken.node_token_id)

          if (index >= 0) this.nodeNodeToken.splice(index, 1)

          this.selectedNodeToken = {...{}}

          this.$refs.remove_nodetoken_confirm_modal.closeModal()

          this.getNodeTokenList()
        })
    },

    getNodeTokenedNodeAccessUrl() {
      const parentId = this.node.type == "folder" ? this.node.node_id : this.node.node_parent

      const route = router.resolve({
        name: 'ContentNavigation',
        params: { appId: this.$route.params.appId, parentId }
      })

      if (route.href == "/") {
        return this.$el.baseURI
      }

      return new URL(route.href, window.location.origin).href
    },

    copyKeyToClipboard(key) {
      navigator.clipboard.writeText(key)

      this[AppActions.OPEN_APP_INFO_MESSAGE](this.$t('nodetoken_to_clipboard'))
    },

    getUserAvatarUrl(userId) {
      return (this.users.find(el => el.users_id == userId)?.avatar_url) ?? null
    },

    getUserName(userId) {
      return (this.users.find(el => el.users_id == userId)?.name) ?? `${this.$t('user')} ID: ${userId}`
    },

    formatDate(timestamp) {
      return HumanReadable.epochTimestampToDate(timestamp, true)
    },

    clearForm() {
      this.isRecursive = true
    },

    open(node) {
      this.node = {...node}

      this.isCurrent = false
      this.isEmpty = false
      this.clearForm()

      this.$refs.nodetoken_access_modal.openModal()

      this.fetchData()
    },

    close() {
      this.$refs.nodetoken_access_modal.closeModal()
    },
  },
}

</script>

<template>
  <v-container>
    <v-row>
      <v-col
        v-for="field in fieldsToShow"
        :key="field.key"
        :cols="field.cols ? field.cols : 12"
      >

        <v-text-field
          v-if="(field.type == 'text' || field.type == 'password' || field.type == 'number')"
          :label="field.label"
          v-model="field.value"
          :type="field.type"
          :required="field.required ? field.required : false"
          :rules="field.rules && !field.readonly ? field.rules : []"
          :prepend-inner-icon="field.prependInnerIcon ? field.prependInnerIcon : null"
          :autofocus="field.autofocus ? true : false"
          :readonly="field.readonly ? true : false"
          :disabled="field.disabled ? true : false"
          @input="slugFormatValue(fieldsToShow[field.replicateValToIdx], $event)"
          v-mask="field.mask ? field.mask : ''"
          :name="field.name || field.label"
          outlined
          dense
        ></v-text-field>

        <v-text-field
          v-if="(field.type == 'slug')"
          :label="field.label"
          v-model="field.value"
          @input="slugFormatValue(field, $event)"
          type="text"
          :required="field.required ? field.required : false"
          :rules="field.rules && !field.readonly ? field.rules : []"
          :prepend-inner-icon="field.prependInnerIcon ? field.prependInnerIcon : null"
          :autofocus="field.autofocus ? true : false"
          :readonly="field.readonly ? true : false"
          :disabled="field.disabled ? true : false"
          :name="field.name || field.label"
          outlined
          dense
        ></v-text-field>

        <v-textarea
          v-if="(field.type == 'textarea')"
          :label="field.label"
          v-model="field.value"
          :required="field.required ? field.required : false"
          :rules="field.rules && !field.readonly ? field.rules : []"
          :prepend-inner-icon="field.prependInnerIcon ? field.prependInnerIcon : null"
          :autofocus="field.autofocus ? true : false"
          :readonly="field.readonly ? true : false"
          :disabled="field.disabled ? true : false"
          :name="field.name || field.label"
          outlined
          dense
        ></v-textarea>

        <v-select
          v-if="field.type == 'select'"
          v-model="field.value"
          :items="field.items"
          :item-text="field.itemText ? field.itemText : null"
          :item-value="field.itemValue ? field.itemValue : null"
          :chips="field.chips ? true : false"
          :label="field.label"
          :multiple="field.multiple ? true : false"
          :readonly="field.readonly ? true : false"
          :rules="field.rules && !field.readonly ? field.rules : []"
          @change="dispatchEvent(field, $event)"
          :name="field.name || field.label"
          outlined
        ></v-select>

        <v-checkbox
          v-if="field.type == 'checkbox'"
          :label="field.label"
          v-model="field.value"
          :name="field.name || field.label"
        ></v-checkbox>

        <v-switch
          v-if="field.type == 'switch'"
          v-model="field.value"
          :label="field.label"
          :name="field.name || field.label"
        ></v-switch>

        <SelectFileField
          v-if="field.type == 'select-file'"
          :value="field.value"
          :label="field.label"
          :icon="field.icon"
          :icon-color="field.iconColor"
          :accepts="field.accepts"
          :click-text="field.clickText"
          :select-text="field.selectText"
          :modal-title="field.modalTitle"
          :parent-node-id="field.parentNodeId"
          :multiple-selelect="field.multipleSelect"
          @selectNodes="setSelectFileValue(field, $event)"
        ></SelectFileField>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import SelectFileField from '@/components/content/default/Form/SelectFileField'

export default {
  name: 'DynamicFormContent',

  components: {
    SelectFileField
  },

  props: {
    fields: {
      type: Array
    }
  },

  methods: {
    dispatchEvent(field, value) {
      if (field.changeEvent) {
        this.$emit(field.changeEvent, value)
      }

      this.changeDependentItems(field, value)

      this.toggleVisibilityDenpendentItems(field, value)
    },

    changeDependentItems(dependencyField, value) {
      const dependentFields = this.fields.filter(f => f.dependencyFieldKey == dependencyField.name)

      if (dependentFields) {
        dependentFields.forEach(field => {
          field.items = [...field.itemsToFilter.filter(i => i.dependencyVals.includes(value))]

          field.value = null
        })
      }
    },

    toggleVisibilityDenpendentItems(dependencyField, value) {
      const dependentFields = this.fields.filter(f => f.visibilityDependencyFieldKey == dependencyField.name)

      if (dependentFields) {
        dependentFields.forEach((field) => {
          Vue.set(field, 'hide', ! field.visibilityDependencyVals.includes(value))
        })
      }
    },

    slugFormatValue(field, value) {
      if (!field || !field.replicable) {
        return
      }

      if (!value) {
        field.value = value

        return value
      }

      const formattedValue = value
            .toLowerCase()
            .replace(
              /(á|à|â|ã|é|è|ê|í|ï|ó|ô|õ|ö|ù|ú|ü|û|ñ|ç|·|\/|-|,|:|;)/gi,
              char => this.swapInvalidChars(char)
            )
            .replace(/[^\w ]+/g, '')
            .replace(/\s+/g, '_')

      field.value = formattedValue

      return formattedValue
    },

    swapInvalidChars(char) {
      const invalid = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/-,:;';
      const valid = 'aaaaaeeeeeiiiiooooouuuunc______';

      return valid.charAt(invalid.indexOf(char))
    },

    setSelectFileValue(item, data) {
      if (data.length == 0) {
        item.value = null
        return
      }

      if (item.multipleSelect) {
        item.value = data.map(node => node.node_id)
        return
      }

      if (Array.isArray(data) && data.length > 0) {
        item.value = data[0].node_id
      }
    },

    convertStringRulesToArray(field) {
      if (field.rules && typeof field.rules === 'string') {
        field.rules = eval(field.rules)
      }

      return field
    },
  },

  computed: {
    fieldsToShow() {
      return this.fields.filter(f => !f.hide).map(f => {
        return this.convertStringRulesToArray(f)
      })
    },
  },
}
</script>
